import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { getRequest } from "../interceptor/interceptor";
import { GET_MARGIN } from "../../apiConst";
import moment from "moment";

const MarginBreakdown = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getMarginData();
  }, []);

  const getMarginData = () => {
    setIsLoading(true);
    getRequest(`${GET_MARGIN}`)
      .then((res) => {
        if (res) {
          setData(res);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const renderUserData = (record) => {
    // Filter out the specified keys
    const keys = Object.keys(record).filter((key) => 
      !["totalFund", "balance", "userId", "createdAt", "_id","__v"].includes(key)
    );
  
    return keys.map((key, index) => (
      <Typography
        key={index}
        variant="body2"
        style={{ color: "#555", margin: "2px 0" }}
      >
        {key.toUpperCase()}: <span style={{ fontWeight: 600 }}>{record[key]}</span>
      </Typography>
    ));
  };

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden m-3 mb-6">
      {/* Header */}
      <div className="bg-gray-700 text-white p-4 flex justify-between">
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Margin Calculations
        </Typography>
        <Button
          onClick={() => navigate("/add-margin")}
          variant="contained"
          color="info"
          style={{ fontWeight: "bold" }}
        >
          Add New
        </Button>
      </div>

      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <div className="p-4">
          <Grid container direction="column" spacing={3}>
            {data.map((record, index) => (
              <Grid item key={record._id}>
                <Card style={{ backgroundColor: "#edf0f0", borderRadius: "10px" }}>
                  <CardContent>
                    <Typography
                      variant="h6"
                      component="h2"
                      style={{ color: "#333", fontWeight: "bold", marginBottom: "8px" }}
                    >
                      Fund Info
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ color: "#000", fontWeight: "bold" }}
                    >
                      Total Amount: <span style={{ fontWeight: 600 }}>${record.totalFund}</span>
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ color: "#000", fontWeight: "bold" }}
                    >
                      Balance: <span style={{ fontWeight: 600 }}>${record.balance}</span>
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ color: "#000", fontWeight: "bold" }}
                    >
                      Created Date: <span style={{ fontWeight: 600 }}>{moment(record.createdAt).format('MM/DD/YYYY')}</span>
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      style={{ color: "#333", fontWeight: "bold", marginTop: "12px" }}
                    >
                      Breakouts
                    </Typography>
                    {renderUserData(record)}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default MarginBreakdown;
