import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';
import { useForm } from 'react-hook-form';
import InputField from '../../Common/InputField';
import MapMarker from './MapMarker';
import { get } from 'lodash';
import CompanyDisplay from './CompanyDisplay';
import { getRequest, postRequest } from '../interceptor/interceptor';
import {
  GET_CITY_FROM_IP_ADDRESS,
  GET_DOMAIN_SETTINGS,
  GET_IP_ADDRESS,
} from '../../apiConst';
import axios from 'axios';
import { FaArrowRight, FaGripVertical, FaList, FaMap } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import SelectDropdown from '../../Common/SelectDropdown';

const libraries = ['places'];

const distanceOptions = {
  miles: 'Miles',
  km: 'Km',
};

const MapFeature = ({ getCompanyByLocation, tableRowsData, setIsLoading }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [filters, setFilters] = useState([]);
  const [placeObj, setPlaceObj] = useState('');
  const [singleView, setSingleView] = useState(false);
  const [mapView, setMapView] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    reset({
      distance: 50,
    });

    getFiltersData();
    getIpAddress();
    setDisplayView();

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((showPosition, showError) => {
    //     const { latitude, longitude } = showPosition.coords;
    //     if (latitude && longitude) {
    //       setLat(latitude);
    //       setLng(longitude);
    //     } else {
    //       setLat(defaultLatLong[0]);
    //       setLng(defaultLatLong[1]);
    //     }
    //   });

    //   navigator.permissions
    //     .query({
    //       name: "geolocation",
    //     })
    //     .then((permission) => {
    //       if (permission.state !== "granted") {
    //         setLat(defaultLatLong[0]);
    //         setLng(defaultLatLong[1]);
    //       }
    //     });
    // }
  }, []);

  useEffect(() => {
    if (lat && lng) {
      setPayload();
    }
  }, [lat, lng]);

  const setDisplayView = () => {
    console.log(searchParams);
    const viewType = searchParams.get('view');
    if (viewType) {
      if (viewType == 'map') {
        setSingleView(true);
        setMapView(true);
      } else if (viewType == 'list') {
        setSingleView(true);
        setMapView(false);
      } else {
        setSingleView(false);
        setMapView(false);
      }
    }
  };

  const getLatLongByIpAddress = (ipAddress) => {
    setIsLoading(true);
    getRequest(`${GET_CITY_FROM_IP_ADDRESS}?ip=${ipAddress}`)
      .then((res) => {
        if (res.data) {
          const { latitude, longitude, city, country_name, region_name } =
            res.data;

          setLat(Number(latitude));
          setLng(Number(longitude));
          setPlaceObj({
            city: city,
            state: region_name,
            country: country_name,
          });
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
    // setLat(defaultLatLong[0]);
    // setLng(defaultLatLong[1]);
    // setPlace("Pune");
  };

  const getFiltersData = () => {
    const payload = {
      domain: 'www.itpros.tech',
    };
    postRequest(`${GET_DOMAIN_SETTINGS}`, payload)
      .then((res) => {
        setFilters(get(res.data.data[0], 'filters.company'));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setPayload = () => {
    let obj = getValues();
    const conversionDetail = {
      km: 1000,
      miles: 1609.34,
    };
    console.log(obj);
    obj = {
      ...obj,
      distance: obj.distance
        ? obj.distance * conversionDetail[obj.distanceType] || 1000
        : 5000,
    };
    const payload = {
      lat,
      long: lng,
      ...obj,
    };

    getCompanyByLocation(payload);
  };

  const getIpAddress = () => {
    const ipAddress = localStorage.getItem('locationIp');
    if (ipAddress) {
      getLatLongByIpAddress(ipAddress);
    } else {
      setIsLoading(true);
      axios
        .get(`${GET_IP_ADDRESS}`)
        .then((res) => {
          setIsLoading(false);
          if (res.data) {
            const { ip } = res.data;
            getLatLongByIpAddress(ip);
            localStorage.setItem('locationIp', ip);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const {
    register,
    getValues,
    reset,
    formState: { errors },
  } = useForm({});

  const onMapClick = (e) => {
    const { latLng } = e;
    setLat(latLng.lat());
    setLng(latLng.lng());
  };

  const onBlur = (e) => {
    setPayload();
  };

  const setPlaceDetails = (singlePlace) => {
    let obj = {};
    get(singlePlace, 'address_components').forEach((addr) => {
      if (get(addr, 'types').includes('locality')) {
        obj['city'] = get(addr, 'long_name');
      } else if (get(addr, 'types').includes('administrative_area_level_1')) {
        obj['state'] = get(addr, 'long_name');
      } else if (get(addr, 'types').includes('country')) {
        obj['country'] = get(addr, 'long_name');
      }
    });
    setPlaceObj(obj);
  };

  const onPlaceChange = (singlePlace) => {
    setPlaceDetails(singlePlace);
    setLat(singlePlace.geometry.location.lat());
    setLng(singlePlace.geometry.location.lng());
  };

  const onMapLoad = () => {
    setMapLoaded(true);
  };

  const mapViewToggle = () => {
    setSingleView(true);
    setMapView(!mapView);
    searchParams.set('view', mapView ? 'list' : 'map');
    setSearchParams(searchParams);
  };

  const offSingleView = () => {
    setSingleView(false);
    setMapView(false);
    searchParams.set('view', 'both');
    setSearchParams(searchParams);
  };

  return (
    <>
      <div className="m-4">
        <form>
          {mapLoaded && (
            <div className="flex md:flex-row xs:flex-col w-full">
              <div className="mb-3 flex flex-row">
                <span className="mr-3 ml-2 mt-2 w-1/3">Location</span>
                <div className="flex flex-col w-2/3">
                  <Autocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                    onPlaceSelected={(singlePlace) => {
                      onPlaceChange(singlePlace);
                    }}
                    defaultValue={placeObj['city']}
                    className="h-10  shadow appearance-none border border-gray-200 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  <div className="md:absolute xs:relative md:mt-12">
                    {placeObj && (
                      <div className="flex">
                        {placeObj['country']} <FaArrowRight className="m-1" />{' '}
                        {placeObj['state']} <FaArrowRight className="m-1" />{' '}
                        <span className="text-primary-600">
                          {placeObj['city']}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {filters &&
                filters?.map((filter) => {
                  return (
                    <div className="flex leading-9 mb-2" key={filter.value}>
                      <span className="mr-3 ml-2 w-1/3">{filter.label}</span>
                      <InputField
                        fieldName={filter.value}
                        register={register}
                        onBlur={onBlur}
                        errors={errors}
                        fieldType={filter.type}
                        className="h-10 w-2/3 shadow appearance-none border border-gray-200 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder={`${filter.label}...`}
                        style={{ color: 'black' }}
                      />
                      {filter.value === 'distance' && (
                        <SelectDropdown
                          fieldName={'distanceType'}
                          options={distanceOptions}
                          register={register}
                          onChange={onBlur}
                          errors={errors}
                          fieldType="select"
                          className="h-10 w-2/3 shadow appearance-none border border-gray-200 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          emptyOption={false}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </form>
      </div>
      {lat && lng && (
        <>
          <div className="flex md:flex-row xs:flex-col">
            <div
              className={`p-5 ${
                singleView
                  ? mapView
                    ? `w-full`
                    : 'hidden'
                  : `md:w-1/2 xs:w-full sm:w-full`
              } `}
              style={{ height: 'auto' }}
            >
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                libraries={libraries}
              >
                <GoogleMap
                  mapContainerStyle={{ height: '50vh', width: '100%' }}
                  center={{ lat, lng }}
                  zoom={10}
                  onLoad={onMapLoad}
                  onClick={onMapClick}
                >
                  {tableRowsData && (
                    <>
                      {tableRowsData &&
                        tableRowsData.length > 0 &&
                        tableRowsData.map((place, index) => {
                          const coords = get(place, 'location.coordinates');
                          return (
                            coords?.length > 0 && (
                              <MapMarker
                                position={{
                                  lat: coords[1],
                                  lng: coords[0],
                                }}
                                place={place}
                                key={place?._id + index}
                              />
                            )
                          );
                        })}
                    </>
                  )}
                </GoogleMap>
              </LoadScript>
            </div>
            <div
              className={`${
                singleView
                  ? !mapView
                    ? `w-full`
                    : 'hidden'
                  : `md:w-1/2 xs:w-full sm:w-full`
              }`}
            >
              {tableRowsData && tableRowsData.length > 0 && (
                <CompanyDisplay
                  tableRowsData={tableRowsData}
                  singleView={singleView}
                />
              )}
            </div>
          </div>
          <div className="mapListToggleSection">
            <div className="mapListBtnSection">
              <button className="mapListBtn md:m-0" onClick={mapViewToggle}>
                {mapView ? (
                  <>
                    View List <FaList className="ml-2" />
                  </>
                ) : (
                  <>
                    View Map <FaMap className="ml-2" />
                  </>
                )}
              </button>
              {singleView && (
                <button
                  className="mapListBtn xs:ml-2 md:ml-2"
                  onClick={() => offSingleView()}
                >
                  View Both <FaGripVertical className="ml-2" />
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MapFeature;
