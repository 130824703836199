export const LOGIN_API = "user/login";
export const GENERATE_OTP = "user/generateOtp";
export const VERIFY_OTP = "user/verify";
export const FORM_BUILDER_H_ADD = "formBuilder?entity=";
export const FORM_BUILDER_D_ADD = "formBuilder/data";
export const GET_LATEST_RECORD = "formBuilder/latestRecord";
export const START_API = "formBuilder/start";
export const GET_IP_ADDRESS = "https://api.ipify.org/?format=json";
export const GET_CATEGORY = "formBuilder/category";
export const UPLOAD_FILE = "file/upload?bucket=itPros";
export const DELETE_FILE = "remove-object";
export const UPLOAD_EXCEL_FILE = "file/excel-data";
export const GET_COMPANY_DETAILS = "oj/company";
export const GET_UPLOADED_FILE_DATA = "oj/EFMD/search";
export const EDIT_EXCEL_META_DATA = "oj/EFMD/edit";
export const GET_EXCEL_FILE_DATA = "oj/EFD/search";
export const EDIT_EXCEL_FILE_DATA = "oj/EFD/edit";
export const EXCEL_DOWNLOAD = "file/excel-download";
export const GET_COLLECTION_ALIAS_TABLE_DETAILS = "oj/TD/search";
export const GET_COMPANY_BY_GEO_LOCATION = "oj/company/geo-search";

export const GET_OJ_ENDPOINT = "oj";
export const GET_DOMAIN_SETTINGS = "oj/setting/search";
export const GET_CITY_FROM_IP_ADDRESS = "ip/lookup";
export const GET_API_DETAILS = "oj/apiDetails";

//margin-breakdown

export const ADD_MARGIN = "elite/add-breakdown";
export const GET_MARGIN= "elite/get-breakdown";
